import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Container from '../components/container'
import Hero from '../components/hero'
import Layout from '../components/layout'
import PortfolioPreview from '../components/portfolio-preview'
import Section from '../components/section'
import ServicePreview from '../components/service-preview'
import TheSquare from '../components/the-square'

const ProcessStep = ({ title, description, number }) => (
  <div style={{ textAlign: 'center' }}>
    <h3>{number}</h3>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
)

class RootIndex extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulHomePage')

    return (
      <Layout location={this.props.location}>
        <Hero
          image={data.heroImages[0].gatsbyImageData}
          title={data.heroTitle}
          content={data.heroDescription}
          ContentContainer={TheSquare}
        />
        <div style={{ marginTop: '160px' }} />
        <Section id="services" title="Our Services">
          <ServicePreview services={data.services.slice(0, 4)} />
        </Section>
        <Section id="process" title="Our Process">
          <Container>
            <div style={{ display: 'flex', columnGap: '32px' }}>
              {data.process.map((x) => (
                <ProcessStep
                  number={x.number}
                  title={x.title}
                  description={x.description?.childMarkdownRemark?.excerpt}
                />
              ))}
            </div>
          </Container>
        </Section>
        <Section id="portfolio" title="Projects">
          <PortfolioPreview posts={data.projects.slice(0, 10)} />
        </Section>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomePage {
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      heroImages {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 424
          height: 212
        )
      }
      process {
        number
        title
        description {
          childMarkdownRemark {
            html
            excerpt
          }
        }
      }
      services {
        title
        cover {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        body {
          childMarkdownRemark {
            html
          }
        }
      }
      projects {
        title
        slug
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
