import React from 'react'
import * as styles from './section.module.css'

const Section = ({ title, children, id }) => {
  return (
    <section id={id} className={styles.container}>
      {title ? (
        <h2 className={styles.header}>
          <span>{title}</span>
        </h2>
      ) : null}
      {children}
    </section>
  )
}

export default Section
